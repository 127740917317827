import {useRouter} from 'next/router';
import React, {createContext, useRef, useEffect, useContext} from 'react';
import rosetta, {Rosetta} from 'rosetta';
import {useConsole} from 'utils/console';
import {anyFunction} from '../../utils/functions';

// types
interface I18nContextValue {
	t: Rosetta<any>['t'];
	locale(lang: string): void;
}

// ----------- context
export const I18nContext = createContext<I18nContextValue>({
	t: (..._args: any[]) => _args[0], // default : the key
	locale: anyFunction
});

// access the context via this hook
export const useTranslation = () => useContext(I18nContext);

// we need to wrap the entire application with this provider
export const I18nProvider = ({children}: {children: any}) => {
	const {wlog} = useConsole();
	const router = useRouter();
	// const [, setTick] = useState(0);
	// the firstRender is hard to obtain in some condition (yes, with router.replace,
	// the page is sometimes downloaded again, and the ref is lost).
	// So if the locale is already define, it will be not fired infinitely
	const firstRender = useRef(false);

	wlog.info(
		'🟢',
		'First Render',
		firstRender,
		'Have locale',
		Boolean(router.locale),
		'locale',
		router.locale
	);

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const i18nWrapper = {
		activeLocale: router.locale,
		t: i18n.t,
		locale: (l: string) => {
			i18n.locale(l);
			// void router
			// 	.replace(
			// 		`${window.location.pathname}${window.location.search}`,
			// 		`${window.location.pathname}${window.location.search}`,
			// 		{
			// 			locale: l,
			// 			shallow: true
			// 		}
			// 	)
			// 	.then(() => {
			// 		console.info('⭐️ Locale changed, rerender...');
			// 		// force rerender to update view
			// 		setTick(tick => tick + 1);
			// 	});
		}
	};

	// for initial SSR render
	if (router.locale && firstRender.current) {
		wlog.info('🟢 Locale is ', router.locale);
		firstRender.current = false;
		i18n.locale(router.locale);
	}

	// when locale is updated
	useEffect(() => {
		if (router.locale) {
			wlog.debug(
				'Translation Router route : ',
				router.asPath,
				router.basePath,
				router.route,
				router.query,
				'to',
				router.locale
			);
			wlog.debug('location pathname', window.location.pathname);

			wlog.warn('Locale changed to ', router.locale);
			i18n.locale(router.locale);
		}
	}, [router, router.locale]);

	return (
		<I18nContext.Provider value={i18nWrapper}>{children}</I18nContext.Provider>
	);
};

// --------------- init
const i18n = rosetta();

export const defaultLanguage = 'fr';
export const languages = ['fr', 'en'];
export const contentLanguageMap = {fr: 'fr-FR', en: 'en-US'};

const translations = {
	en: require('./en.json'),
	fr: require('./fr.json')
};

i18n.set('en', translations.en);
i18n.set('fr', translations.fr);

// default language
i18n.locale(defaultLanguage);
