import {Box} from '@chakra-ui/react';
import React from 'react';

export interface FullPageProps {
	top?: React.ReactElement;
	header?: React.ReactElement;
	footer?: React.ReactElement;
	height?: string;
	isMobile?: boolean;
	children?: any;
}

/** display children full page and centered */
export const FullPageCentered: React.FC<FullPageProps> = ({
	top,
	header,
	footer,
	children,
	isMobile
}: FullPageProps) => (
	<Box maxWidth='1366px' mx='auto'>
		{top}
		{header ? (
			<Box pt={10} px='5%'>
				{header}
			</Box>
		) : null}
		<Box
			px='5%'
			height='max-content'
			minHeight='85vh'
			display='flex'
			minWidth={isMobile ? '100%' : 'max-content'}
			alignItems='center'
			justifyContent='center'
			marginBottom={isMobile ? '75px' : null}
		>
			{children}
		</Box>
		<Box
			sx={{position: 'fixed', bottom: 0, width: '100%', zIndex: 1000}}
			maxHeight='70px'
		>
			{footer}
		</Box>
	</Box>
);

export const FullPageNormal: React.FC<FullPageProps> = ({
	top,
	header,
	footer,
	children,
	isMobile
}: FullPageProps) => (
	<Box
		width='100%'
		mx='auto'
		maxWidth='1366px'
		paddingBottom={isMobile ? '70px' : '20px'}
	>
		{top}
		{header ? (
			<Box pt={10} px='5%'>
				{header}
			</Box>
		) : null}
		<Box
			px='5%'
			minHeight='90vh'
			width='100%'
			height='max-content'
			display='flex'
		>
			{children}
		</Box>
		<Box
			sx={{position: 'fixed', bottom: 0, width: '100%', zIndex: 1000}}
			maxHeight='70px'
		>
			{footer}
		</Box>
	</Box>
);
export const FullPageAll: React.FC<FullPageProps> = ({
	top,
	header,
	footer,
	children,
	isMobile
}: FullPageProps) => (
	<Box mx='auto' maxWidth='1366px'>
		{top}
		{header ? (
			<Box pt={10} px='5%'>
				{header}
			</Box>
		) : null}
		<Box
			mt={6}
			sx={{
				minHeight: '80vh',
				display: 'flex',
				height: 'max-content'
			}}
		>
			{children}
		</Box>
		<Box
			sx={{position: 'fixed', bottom: 0, width: '100%', zIndex: 1000}}
			maxHeight='70px'
		>
			{footer}
		</Box>
	</Box>
);
