import {onCondition} from './nextTools';
import {httpMock} from 'utils/httpMock';
import {useConsole} from 'utils/console';
import React, {PropsWithChildren} from 'react';

/**
 * Activate the mock according to env variable.
 *
 * @returns a null reponse.
 */
export const MockActivator: React.FC<PropsWithChildren<{}>> = ({children}) => {
	const {wlog} = useConsole();
	const [_mockLoaded, _setMockLoaded] = React.useState(false);

	React.useEffect(() => {
		// we mock our networking layer
		onCondition(
			process.env.NEXT_PUBLIC_USE_MOCK === 'true',
			() => {
				// to be launched only in navigator.
				if (typeof window !== 'undefined') {
					if (!(window as any).httpMock) {
						(window as any).httpMock = httpMock({environment: 'development'});
					}
				}
				wlog.debug('[startup] the API network is 🟠 mocked');
			},
			() => {
				wlog.debug('[startup] the API network is 🟢 real');
			}
		);
		_setMockLoaded(true);
	}, []);

	if (!_mockLoaded) {
		return null;
	}

	return <>{children}</>;
};
