import React from 'react';
import {useConsole} from 'utils/console';
import {FullPageLoading} from '../../../components/Loading';
import {useCurrentUser} from './hook';

interface AuthenticationDispatchProps {
	children: React.ReactNode;
}

/** reads the current authentication state and redirects to login if  */
export const AuthenticationDispatch: React.FC<AuthenticationDispatchProps> = ({
	children
}: AuthenticationDispatchProps) => {
	const {wlog} = useConsole();
	wlog.info('Authent Dispatch rendering...');

	const {
		authenticationChecking,
		isUserLoading,
		authenticatedRoute,
		isUserError
	} = useCurrentUser();

	wlog.info('After user...');

	if (authenticatedRoute && (authenticationChecking || isUserLoading)) {
		wlog.info('Authenticated route');
		return <FullPageLoading />;
	}

	if (authenticationChecking && !authenticatedRoute) {
		wlog.info('Unauthenticated route');
		return <FullPageLoading />;
	}

	// if error, means the content should be authenticated.
	// there are never errors when the route is unauthenticated.
	// 💎 we display a spin until a mechanism somewhere is routing to login url.
	// 🚨 unauthenticated != public route. TODO: please manage public route when needed.
	return isUserError ? <FullPageLoading /> : <>{children}</>;
};
