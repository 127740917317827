import {Select} from '@chakra-ui/react';
import {languages, useTranslation} from 'modules/i18n';
import {useRouter} from 'next/router';
import React from 'react';
import {useConsole} from 'utils/console';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LanguageSelectorProps {
	/**
	 * warn parent that the language has changed.
	 */
	onChange?: (value: string) => void;
}

// TODO:
export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
	onChange
}: LanguageSelectorProps) => {
	const {t, locale} = useTranslation();
	const router = useRouter();
	const {wlog} = useConsole();
	const changeLanguage = (e: {target: {value: any}}) => {
		const language: string = e.target.value;
		void router.replace(
			`${router.asPath}${window.location.search ? '' : window.location.search}`,
			`${router.asPath}${window.location.search ? '' : window.location.search}`,
			{
				locale: language,
				shallow: true
			}
		);
		locale(language);
		if (onChange) {
			onChange(language);
		}
	};

	wlog.debug('router as path', router.asPath);
	wlog.debug('router as pathname', router.pathname);
	wlog.debug('window.location', `${window.location.search}`);
	wlog.debug('languages: ', languages);

	return (
		<>
			<Select
				defaultValue={router.locale}
				size='md'
				variant='unstyled'
				// onSelect={}
				onChange={changeLanguage}
			>
				<option value='fr'>{t('languages.fr')}</option>
				<option value='en'>{t('languages.en')}</option>
			</Select>
		</>
	);
};

export default LanguageSelector;
