import {createServer, Response, Model, Request} from 'miragejs';
import {
	DataSet,
	mockOnCondition,
	responseBuilder,
	RouteSet,
	Sample,
	Scenarii
} from 'utils/mockSystem/api';
import _ from 'lodash';
import {getNewRefiningFormScenarii} from 'utils/mockScenarii/scenariiNewRefiningForm';
import {
	retrieveCachedScenarioSampleToken,
	retrieveCachedScenarioToken
} from './scenarioMock';

// DATA SET
const mockDS: Scenarii = new Map<string, RouteSet>([
	['default', new Map<string, DataSet>()],
	['newRefiningForm', getNewRefiningFormScenarii()]
]);

// API Mock
export const httpMock = ({environment = 'test'}) => {
	console.log('⚪️', 'mockingInProcess', mockDS);

	const server = createServer({
		environment,
		models: {
			user: Model
		},
		routes() {
			this.passthrough('/_next/**');
			this.passthrough('/__nextjs_original-stack-frame');
			this.passthrough('/en/**');
			this.passthrough('/fr/**');
			this.urlPrefix = process.env.NEXT_PUBLIC_BACKEND_URL;
			this.timing = 800;

			this.pretender.unhandledRequest = (verb, path, request) => {
				console.warn(`🔴 Please add a route for ${path}`);
				throw new Error('Argh');
			};

			this.pretender.erroredRequest = (verb, path, request, error) => {
				console.warn(`🟥 Error trying to request ${path}`, error);
			};

			mockOnCondition(this, '/debug', route => {
				this.post(route, (_, request) => {
					console.log(route);
					if (request.requestHeaders.Authorization !== 'Bearer a-token')
						return new Response(401);
				});
			});

			// tries to execute every root that match.
			const scenario = retrieveCachedScenarioToken() ?? 'default';
			for (const [_route, _dataset] of mockDS.get(scenario ?? 'default')) {
				mockOnCondition(this, _route, route => {
					for (const [_method, _switch] of _dataset) {
						this[_method](
							route,
							_switch.handler ??
								((schema: any, request: Request) => {
									console.log(
										request.url,
										'Initial request data',
										request.requestBody
									);
									const queryParameter = request.queryParams;

									const parsedBody = JSON.parse(request.requestBody);
									const subScenario = retrieveCachedScenarioSampleToken();
									console.log(
										'Trying to find the Mocked Request:',
										request.url,
										'Scenario:',
										`'${scenario}'`,
										'Sample:',
										`'${subScenario}'`,
										'Body Content:',
										parsedBody
									);
									let _found: Sample = undefined;
									_found = _switch.samples.find(
										_el =>
											((subScenario
												? _.isEqual(_el.name, subScenario)
												: _.isEqual(_el.name, 'default')) ||
												_.isEqual(_el.name, undefined) ||
												_.isEqual(_el.name, null)) &&
											_.isEqual(_el.givenInput, parsedBody) &&
											_.isEqual(_el.givenQueryParameters ?? {}, queryParameter)
									);

									if (_found) {
										console.log('🥳', 'Potential response :', _found);
									} else {
										console.error(
											'🔴',
											'No response found among',
											_switch,
											'Please add a corresponding response to mock with',
											'scenario',
											`'${scenario}'`,
											'query parameter',
											queryParameter,
											'body',
											parsedBody
										);
									}

									return responseBuilder(_found, _switch.elseOutput);
								})
						);
					}
				});
			}
		}
	});
	return server;
};
