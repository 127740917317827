// This module contains next specific components

import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';

interface ClientReadyProps {
	children?: React.ReactNode;
}

/**
 * This component take care of the Server Side Optimization.
 * It ensures the Router.query data is available to children,
 * because in server side generation, this content is not available.
 *
 * So, a spinner is displayed instead => Means should be obligatorily used as children of <ChakraProvider>.
 * The spinner takes place on all the available screen.
 */
export const ClientReady: React.FC<ClientReadyProps> = ({
	children
}: ClientReadyProps) => {
	const router = useRouter();
	const [queryReady, setQueryReady] = useState(false);

	if (router?.query) {
		console.info('⭐️ Query data detected', router.query);
	}

	useEffect(() => {
		setQueryReady(true);
	}, []);

	return queryReady ? <>{children}</> : null;
};
