const SCENARIO_KEY = 'k_scenario_token';
const SCENARIO_SAMPLE_KEY = 'k_sample_token';

export const saveScenarioToken: (scenario: string) => void = (
	scenario: string
) => {
	if (typeof window !== 'undefined') {
		window?.localStorage.setItem(SCENARIO_KEY, scenario);
		console.log('saved new scenario', scenario);
	}
};

export const retrieveCachedScenarioToken: () => string | undefined = () => {
	if (typeof window !== 'undefined') {
		return window?.localStorage.getItem(SCENARIO_KEY);
	}

	return undefined;
};

export const clearScenarioToken: () => void = () => {
	if (typeof window !== 'undefined') {
		window?.localStorage.removeItem(SCENARIO_KEY);
		console.log('scenario cleared');
	}
};

// ///
// Specific samples of a scenario
// ///

export const saveScenarioSampleToken: (scenario: string) => void = (
	scenario: string
) => {
	if (typeof window !== 'undefined') {
		window?.localStorage.setItem(SCENARIO_SAMPLE_KEY, scenario);
		console.log('saved new scenario', scenario);
	}
};

export const retrieveCachedScenarioSampleToken: () =>
	| string
	| undefined = () => {
	if (typeof window !== 'undefined') {
		return window?.localStorage.getItem(SCENARIO_SAMPLE_KEY);
	}

	return undefined;
};

export const clearScenarioSampleToken: () => void = () => {
	if (typeof window !== 'undefined') {
		window?.localStorage.removeItem(SCENARIO_SAMPLE_KEY);
		console.log('scenario cleared');
	}
};
