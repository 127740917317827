import {Response, Server, Registry} from 'miragejs';

import {RouteHandler} from 'miragejs/server';
import {AnyFactories, ModelDefinition} from 'miragejs/-types';

import {ApiError} from 'utils/errors';

// mocked endpoint
export const mockedApis: string[] = (() => {
	let _mocked: string[] = process.env.NEXT_PUBLIC_MOCKED_API?.split(',') ?? [
		'*'
	];
	if (process.env.NEXT_PUBLIC_MOCKED_API === '') {
		_mocked = ['*'];
	}
	console.log('Mocked list : ', _mocked);
	return _mocked;
})();

/**
 * This function executes the callback only if route is mocked in the configuration.
 * Callback arrow function should define the result of the mocked function.
 * You can define mocked route by adding comma separated route in the constant NEXT_PUBLIC_MOCKED_API.
 *
 * @param server The mock server.
 * @param route The route to mock.
 * @param cb The arrow function if the route is mocked.
 */
export const mockOnCondition: (
	server: Server,
	route: string,
	cb: (route: string) => void
) => void = (server, route, cb) => {
	if (mockedApis.includes(route) || mockedApis.includes('*')) {
		cb(route);
	} else {
		server.passthrough(route);
	}
};

export const responseBuilder: (
	sample: Sample,
	elseOutput: ApiError
) => Response = (sample, elseOutput) => {
	console.info('🤖', 'Sample : ', sample, 'else', elseOutput);
	return sample
		? new Response(sample.withStatusCode, undefined, sample.thenOutput)
		: new Response(elseOutput.statusCode, undefined, elseOutput);
};

/**
 * A dataset.
 */
export interface Sample {
	name?: 'default' | string;
	givenInput: any;
	givenQueryParameters?: Record<string, string>;
	thenOutput: any | ApiError;
	withStatusCode: number;
}

export interface DataSwitch {
	samples?: Sample[];
	elseOutput?: ApiError;
	/** Handler alternative */
	handler?: RouteHandler<
		Registry<
			{
				user: ModelDefinition<{}>;
			},
			AnyFactories
		>
	>;
}

export type DataSet = Map<string, DataSwitch>;
export type RouteSet = Map<string, DataSet>;
export type Scenarii = Map<string, RouteSet>;
