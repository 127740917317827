// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/?_ga=2.14547253.347678505.1622561192-864813189.1618824606

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
		SENTRY_DSN ||
		'https://03d022e9d09e4d7789488422127ff806@o366795.ingest.sentry.io/5169238',
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 0.25
	// see : https://docs.sentry.io/platforms/javascript/configuration/sampling/
	// tracesSampler: samplingContext => {
	//     // Examine provided context data (including parent decision, if any) along
	//     // with anything in the global namespace to compute the sample rate or
	//     // sampling decision for this transaction
	//     if ("...") {
	//       // These are important - take a big sample
	//       return 0.5;
	//     } else if ("...") {
	//       // These are less important or happen much more frequently - only take 1%
	//       return 0.01;
	//     } else if ("...") {
	//       // These aren't something worth tracking - drop all transactions like this
	//       return 0;
	//     } else {
	//       // Default sample rate
	//       return 0.1;
	//     }
	//   }
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
