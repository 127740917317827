import {AxiosResponse} from 'axios';
import {ApiError} from 'next/dist/server/api-utils';
import {NextRouter} from 'next/router';
import {useEffect, useState} from 'react';
import useSWR from 'swr';
import {useConsole} from 'utils/console';
import {httpFetcher} from 'utils/http';
import {useAuthentication} from '.';
import {unauthenticatedRoutes} from '../../navigation';
import {useLocalizedNavigation} from '../../navigation/hooks';
import {getCurrentPathnameWithoutLocale} from '../../navigation/utils';
import {apiUserRoute, UserSuccess} from './api';
import {clearAuthenticationToken, getCachedAuthenticationToken} from './token';

/**
 * Only check if the page should be authenticated.
 * 🚨 Does not handle yet public pages.
 */
const shouldCheckAuthentication = (router: NextRouter) => {
	const _currentPath: string = getCurrentPathnameWithoutLocale(router);
	console.log('Detected path : ', _currentPath, 'with locale', router.locale);
	let _found = false;
	for (const _route of unauthenticatedRoutes) {
		if (typeof _route.matcher !== 'undefined') {
			if (_route.matcher.test(_currentPath)) {
				console.info(_currentPath, 'is unauthenticated by pattern');
				_found = true;
				break;
			}
		} else if (_currentPath.startsWith(_route.build())) {
			console.info(_currentPath, 'is unauthenticated by start match');
			_found = true;
			break;
		}
	}
	return !_found;
};

/**
 *
 * @returns authenticatedRoute / authenticationChecking
 */
export const useAuthenticationHook = () => {
	const {wlog} = useConsole();
	const {router, replace} = useLocalizedNavigation();
	const [authenticationChecking, setAuthenticationChecking] = useState(true);
	const authenticatedRoute = shouldCheckAuthentication(router);
	const [user, setUser] = useState<UserSuccess>(undefined);

	const shouldRedirect = () => getCurrentPathnameWithoutLocale(router) === '/';

	wlog.debug('Authent hook');

	/** initialize the authentication from the current browser state */
	const initAuthentication = () => {
		if (shouldCheckAuthentication(router)) {
			wlog.info('Check authentication...');
			const token = getCachedAuthenticationToken();
			if (token) {
				wlog.info('Has token');
				if (shouldRedirect()) replace('home');
			} else {
				wlog.info('Token not here');
				clearAuthenticationToken();
				replace('authLogin');
			}
		} else {
			// 🚧 TODO: upgrade this logic to a more flexible on in the future. (ie : Public route)
			clearAuthenticationToken();
		}
		setAuthenticationChecking(false);
	};

	// onInitialization, attempt to seed the authentication state
	// 💎 : on client side only, the first time.
	// 🚨 : it shouldn't have direct link from authenticated page to Unauthenticated content, cause you may have
	// authenticated content 'glitch' being displayed. Please use address bar link instead.
	// 🚨 Please be aware that an UNAUTHENTICATED route CANNOT be viewed by an authenticated USER.
	// 🚨 it is different from a PUBLIC route, that can be viewed by UN/AUTHENTICATED User/Guest.
	useEffect(() => {
		initAuthentication();
	}, []);

	return {
		authenticationChecking,
		authenticatedRoute,
		user,
		setUser
	};
};

/**
 * @returns the user if this is an authenticated route
 */
export const useUser = (authenticatedRoute: boolean) => {
	const {data, error, mutate} = useSWR<UserSuccess, AxiosResponse<ApiError>>(
		authenticatedRoute ? apiUserRoute() : null,
		httpFetcher,
		{
			refreshInterval: 0
		}
	);

	return {
		user: data,
		error,
		mutate,
		isLoading: !error && !data && authenticatedRoute, // loading mode only for authenticated route
		isError: Boolean(error)
	};
};

export const useCurrentUser = () => {
	const {authenticationChecking, authenticatedRoute} = useAuthentication();
	const {isLoading, error, user, mutate} = useUser(authenticatedRoute);

	return {
		authenticatedRoute,
		authenticationChecking,
		isUserLoading: isLoading,
		isUserError: error,
		user,
		mutate
	};
};
