import {useRouter} from 'next/router';
import {RouteNames, Routes} from '.';
import {withLang} from './utils';
import {createContainer} from 'unstated-next';
import {useConsole} from 'utils/console';
import React from 'react';
import {
	clearScenarioSampleToken,
	clearScenarioToken,
	retrieveCachedScenarioSampleToken,
	retrieveCachedScenarioToken,
	saveScenarioSampleToken,
	saveScenarioToken
} from 'utils/scenarioMock';

/**
 * Use this hook as *Router* replacement.
 * Should be called only once by the context.
 */
const useLocalizedNavigationHook = () => {
	const {wlog} = useConsole();
	const router = useRouter();
	const hash: string = router.asPath.split('#')[1] || '';
	const scenario: string = hash.match(/scenario=[A-Za-z0-9_\-]+/)
		? hash.split('=')[1]
		: null;

	const sample: string = hash.match(/sample=[A-Za-z0-9_\-]+/)
		? hash.split('=')[1]
		: null;

	const updateScenario = () => {
		const _formerScenario = retrieveCachedScenarioToken();
		if (scenario === 'default') {
			clearScenarioToken();
		} else if (_formerScenario !== scenario && scenario !== null) {
			saveScenarioToken(scenario);
		}
	};

	const updateSample = () => {
		const _formerScenario = retrieveCachedScenarioSampleToken();
		if (sample === 'default') {
			clearScenarioSampleToken();
		} else if (_formerScenario !== sample && sample !== null) {
			saveScenarioSampleToken(sample);
		}
	};

	React.useEffect(() => {
		updateScenario();
		updateSample();
	}, [router]);

	return {
		/**
		 * Push a new url path to history.
		 *
		 * @param path
		 * @param args
		 */
		push(path: RouteNames, ...args: any[]): void {
			const _route = withLang(Routes[path].build(...args));
			router
				.push(
					scenario !== null
						? {href: _route, hash: `scenario=${scenario}`}
						: _route,
					undefined,
					{locale: router.locale}
				)
				.then(result => {
					wlog.info(`Push history ${router.pathname} by ${_route}`);
				})
				.catch(reason => {
					wlog.info(
						`Push history ${router.pathname} by ${_route} failed :`,
						reason
					);
				});
		},
		/**
		 * In place replace current url location in history.
		 *
		 * @param path
		 * @param args
		 */
		replace(path: RouteNames, ...args: any[]): void {
			const _route = withLang(Routes[path].build(...args));
			router
				.replace(
					scenario !== null
						? {href: _route, hash: `scenario=${scenario}`}
						: _route,
					undefined,
					{locale: router.locale}
				)
				.then(result => {
					console.info(`Replace history ${router.pathname} by ${_route}`);
				})
				.catch(reason => {
					wlog.error(
						`Replace history ${router.pathname} by ${_route} failed :`,
						reason
					);
				});
		},
		/**
		 * Reload the current page.
		 */
		reload(): void {
			wlog.info(`Refresh url ${router.pathname}`);
			router.reload();
		},
		/**
		 * Retrieve the current url path with the identified arguments.
		 */
		getPath(path: RouteNames, ...args: any[]): string {
			return withLang(Routes[path].build(...args));
		},
		router: router
	};
};

const LocalizedNavigationContext = createContainer(useLocalizedNavigationHook);

export const LocalizedNavigationProvider = LocalizedNavigationContext.Provider;
/**
 * The hook to access the context.
 * This hook ensures only one version of the router is used all along the page.
 * For this function to work, please instantiate a component ancester of type <LocalizedNavigationProvider>.
 */
export const useLocalizedNavigation = LocalizedNavigationContext.useContainer;

/**
 * An hook to read query parameters.
 * A query parameter is the value of an http parameter like this /resource?value1=something&value2=else :
 * - query parameter `value1` have the value 'something'.
 * - query parameter `value2` have the value 'else'.
 *
 * @see LocalizedNavigationProvider
 *
 * The router comes from the context <LocalizedNavigationProvider>.
 *
 * @returns Different parameter query functions.
 */
export const useQueryParameters = () => {
	const query = useLocalizedNavigation().router.query;

	return {
		/**
		 * Gets the value of a query param by its name.
		 *
		 * 🚨 Does not support array value here.
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The string value of the parameter
		 */
		queryParam(name: string): string {
			const _param = query[name];
			if (Array.isArray(_param)) {
				throw new TypeError(
					`Query parameter ${name} is supposed to be unique value but was [${_param.join(
						','
					)}]`
				);
			}
			return _param;
		},
		/**
		 * Gets the array of string values of a query param by its name.
		 *
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The array of string values of the parameter.
		 */
		queryParamList(name: string): string[] {
			let _param = query[name];
			if (_param === undefined) {
				return null;
			}
			if (!Array.isArray(_param)) {
				_param = _param.split(',');
			}

			return _param;
		},
		/**
		 * Gets the integer value of a query param by its name.
		 *
		 * 🚨 Does not support array value here.
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The string value of the parameter
		 */
		queryParamAsInteger(name: string, radix = 10): number {
			const _param = query[name];
			if (Array.isArray(_param)) {
				throw new TypeError(
					`Query parameter ${name} is supposed to be unique value but was [${_param.join(
						','
					)}]`
				);
			}

			return Number.parseInt(_param, radix);
		},
		/**
		 * Gets the array of integer values of a query param by its name.
		 *
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The array of integer values of the parameter.
		 */
		queryParamListAsInteger(name: string, radix = 10): number[] {
			let _param = query[name];
			if (_param === undefined) {
				return null;
			}
			if (!Array.isArray(_param)) {
				_param = _param.split(',');
			}

			return _param.map(el => Number.parseInt(el, radix));
		},
		/**
		 * Gets the float value of a query param by its name.
		 *
		 * 🚨 Does not support array value here.
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The float value of the parameter
		 */
		queryParamAsFloat(name: string): number {
			const _param = query[name];
			if (Array.isArray(_param)) {
				throw new TypeError(
					`Query parameter ${name} is supposed to be unique value but was [${_param.join(
						','
					)}]`
				);
			}

			return Number.parseFloat(query[name] as string);
		},
		/**
		 * Gets the array of float values of a query param by its name.
		 *
		 * An array value :
		 * - the parameter name appears more than once
		 * - the parameter contains a comma separated list of values.
		 *
		 * @param name
		 * @returns The array of float values of the parameter.
		 */
		queryParamListAsFloat(name: string): number[] {
			let _param = query[name];
			if (!Array.isArray(_param)) {
				_param = _param.split(',');
			}

			return _param.map(el => Number.parseFloat(el));
		}
	};
};
