import React from 'react';
import {definedAndNotEmpty} from 'utils/strings';
import {
	retrieveCachedScenarioSampleToken,
	retrieveCachedScenarioToken
} from 'utils/scenarioMock';
import {Box, HStack} from '@chakra-ui/layout';

export const DevInfo: React.FC<{}> = () => {
	if (['PROD'].includes(process.env.NEXT_PUBLIC_DEPLOY_ENV ?? 'PROD')) {
		return null;
	}
	const currentScenario = retrieveCachedScenarioToken();
	const currentSample = retrieveCachedScenarioSampleToken();

	// TODO :
	// const bg = renderTextColor(`${process.env.NEXT_PUBLIC_DEPLOY_ENV}${definedAndNotEmpty(currentScenario) ? ' | ' + currentScenario : ''}`);
	return (
		<Box
			position='fixed'
			top='0.5em'
			right='0.5em'
			padding='0.2em'
			w='max-content'
			fontSize='0.8em'
			zIndex={10000}
			backgroundColor='#FCD1CA'
			opacity={0.33}
		>
			<HStack>
				<Box>{process.env.NEXT_PUBLIC_DEPLOY_ENV}</Box>
				{definedAndNotEmpty(currentScenario) ? (
					<>
						<Box>|</Box>
						<Box>{currentScenario}</Box>
					</>
				) : null}
				{definedAndNotEmpty(currentSample) ? (
					<>
						<Box>|</Box>
						<Box>{currentSample}</Box>
					</>
				) : null}
			</HStack>
		</Box>
	);
};
