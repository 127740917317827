/**
 * Execute a arrow function on condition.
 *
 * @param condition
 * @param func
 * @returns TYPE
 */
export const onCondition = <TYPE>(
	condition: boolean,
	func: () => TYPE,
	elseFunc?: () => TYPE
) => {
	return condition ? func() : elseFunc ? elseFunc() : undefined;
};

/**
 * Next JS specific tools : ensures this code to be executed on browser only.
 */
export const onBrowser = <TYPE>(
	func: () => TYPE,
	elseOnServer?: () => TYPE
) => {
	return onCondition(typeof window !== 'undefined', func, elseOnServer);
};

/**
 * Next JS specific tools : ensures this code to be executed on server only.
 */
export const onServer = <TYPE>(
	func: () => TYPE,
	elseOnBrowser?: () => TYPE
) => {
	return onCondition(typeof window === 'undefined', func, elseOnBrowser);
};

/**
 * @returns true if in production-like environment
 */
export const isProdLike = () => {
	return ['PROD', 'STAGE'].includes(process.env.NEXT_PUBLIC_DEPLOY_ENV);
};

/**
 *
 * @returns true if in production ONLY environment
 */
export const isProduction = () => {
	return process.env.NODE_ENV === 'production';
};
