/**
 * Checks if string is not null, undefined and not empty.
 *
 * @param str
 * @returns boolean
 */
export const definedAndNotEmpty = (str?: string) =>
	str !== null && str !== undefined && str.length !== 0;

/**
 * Checks if string looks like an email.
 *
 * @param str
 * @returns true is str looks like and email.
 */
export const looksLikeAnEmail = (str: string) =>
	definedAndNotEmpty(str) && str.includes('@');

/**
 * Ensures that str is never undefined or null.
 * (replace with emppty string in that case)
 */
export const strOrEmpty = (str?: string): string => {
	if (definedAndNotEmpty(str)) {
		return str;
	}

	return '';
};
