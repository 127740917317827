import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {isProdLike} from './nextTools';

export const useLibraryStateHook = () => {
	const _isProdLike: boolean = isProdLike();
	const [recaptchaLoaded, setRecaptchaLoaded] = useState(
		!_isProdLike // true by default if not prod.
	);

	const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

	console.log('🔵 Library hook');

	return {
		isProdLike: _isProdLike,
		recaptchaLoaded,
		setRecaptchaLoaded,
		googleMapsLoaded,
		setGoogleMapsLoaded
	};
};

const LibraryStateContext = createContainer(useLibraryStateHook);

export const LibraryStateProvider = LibraryStateContext.Provider;
export const useLibraryState = LibraryStateContext.useContainer;
