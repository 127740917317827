import {FullPageLoading} from 'components';
import {useLocalizedNavigation} from 'modules/navigation/hooks';
import React from 'react';
import {useConsole} from 'utils/console';

interface MaintenanceContextProps {
	children: React.ReactNode;
}

export const MaintenanceContext: React.FC<MaintenanceContextProps> = ({
	children
}: MaintenanceContextProps) => {
	const {wlog} = useConsole();
	const _maintenance: boolean =
		process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true';
	const {replace} = useLocalizedNavigation();
	//
	const _path: string = window.location.pathname;
	const _isInMaintenancePage = _path.includes('/maintenance');
	React.useEffect(() => {
		if (_maintenance) {
			if (!_isInMaintenancePage) {
				void replace('maintenance');
			}
		}
	}, [_isInMaintenancePage]);

	if (_maintenance && !_isInMaintenancePage) {
		return <FullPageLoading />;
	}

	wlog.debug('Maintenance rendering');
	return <>{children}</>;
};
