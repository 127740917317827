import router, {NextRouter} from 'next/router';
import {Routes} from '.';

export const withLang = (path: string) => path;

export const getCurrentPathnameWithoutLocale = (router: NextRouter) => {
	return router.asPath;
};

export const inLoginPage = () => router.pathname.includes('/login');

/** in rare case, we need to route from outside of a component */
export const routeToLoginImperatively = () => {
	if (inLoginPage()) return;
	router
		.replace(withLang(Routes.authLogin.build()), undefined, {
			locale: router.locale
		})
		.then(result => {
			console.info('Routed successfully to login');
		})
		.catch(reason => {
			console.error('Unable to route to login :', reason);
		});
};

export const routeToError = () => {
	if (router.pathname.includes('/error/403')) return;
	router
		.replace(withLang(Routes.error403.build()), undefined, {
			locale: router.locale
		})
		.then(result => {
			console.info('Routed successfully to page error');
		})
		.catch(reason => {
			console.error('Unable to route to login :', reason);
		});
};
