// import { FullPageLoading } from 'components';
import React, {PropsWithChildren, useEffect} from 'react';
import {createContainer} from 'unstated-next';
import {onCondition} from './nextTools';

/**
 * A wrapper around console to disable debug in production mode.
 */
interface Console {
	log(message?: string, ...optionalParams: any[]): void;

	debug(message: string, ...optionalParams: any[]): void;

	info(message: string, ...optionalParams: any[]): void;

	warn(message: string, ...optionalParams: any[]): void;

	error(message: string, ...optionalParams: any[]): void;
}

// replace the current console in the browser
const useConsoleHook = () => {
	const [wlog, setWLog] = React.useState(console as any as Console);

	const initLog = () => {
		onCondition(
			process.env.NEXT_PUBLIC_DEBUG === 'true' ||
				window.location.hash.includes('DEBUG'),
			() => {
				(window as any).log = function (
					message: string,
					...optionalParams: any
				): void {
					(this as typeof window).console.log(
						`▪ | ${message}`,
						...optionalParams
					);
				};
				(window as any).debug = function (
					message: string,
					...optionalParams: any
				): void {
					(this as typeof window).console.debug(
						`⚪️ | ${message}`,
						...optionalParams
					);
				};
				(window as any).info = function (
					message: string,
					...optionalParams: any
				): void {
					(this as typeof window).console.info(
						`💎 | ${message}`,
						...optionalParams
					);
				};
				(window as any).warn = function (
					message: string,
					...optionalParams: any
				): void {
					(this as typeof window).console.warn(
						`🚧 | ${message}`,
						...optionalParams
					);
				};
				(window as any).error = function (
					message: string,
					...optionalParams: any
				): void {
					(this as typeof window).console.error(
						`🚨 | ${message}`,
						...optionalParams
					);
				};

				console.info('🟢 Console wrap is verbose');
				setWLog(window as any as Console);
			},
			() => {
				(window as any).log = function (
					message: string,
					...optionalParams: any
				): void {};
				(window as any).debug = function (
					message: string,
					...optionalParams: any
				): void {};
				(window as any).info = function (
					message: string,
					...optionalParams: any
				): void {};
				(window as any).warn = function (
					message: string,
					...optionalParams: any
				): void {};
				(window as any).error = function (
					message: string,
					...optionalParams: any
				): void {};

				console.info('🟠 Console wrap is muted');
				setWLog(window as any as Console);
			}
		);
	};

	return {
		wlog,
		initLog
	};
};

export const ConsoleActivator: React.FC<PropsWithChildren<{}>> = ({
	children
}: React.PropsWithChildren<{}>) => {
	const {wlog, initLog} = useConsole();

	useEffect(() => {
		if (wlog === console) {
			initLog();
		}
	}, []);

	if (process.env.NEXT_PUBLIC_DEBUG === 'true' && wlog === console) {
		return null; // I dot not use <FullPageLoading /> intentionally
	}

	return <>{children}</>;
};

const ConsoleContext = createContainer(useConsoleHook);

export const ConsoleProvider = ConsoleContext.Provider;
export const useConsole = ConsoleContext.useContainer;
