import React from 'react';
import {Flex, Box} from '@chakra-ui/react';

export interface NoSideBarProps {
	top: React.ReactElement;
	footer?: React.ReactElement;
	banner?: React.ReactElement;
	isMobile?: boolean;
	children?: any;
}

/** a customized version of the layout */
export const NoSideBar: React.FC<NoSideBarProps> = ({
	top,
	footer,
	banner,
	isMobile,
	children
}: NoSideBarProps) => {
	return (
		<Box width='100%'>
			{top ? (
				<Flex mt={4} width='100%'>
					{top}
				</Flex>
			) : null}
			<Flex
				height={isMobile ? 'max-content' : '100vh'}
				mt={isMobile ? '3em' : null}
			>
				{banner}
				<Flex
					justifyContent='space-between'
					width='100%'
					alignItems='center'
					bg='white'
					maxHeight='1440px'
				>
					<Flex
						width='100%'
						justifyContent='center'
						alignItems='center'
						minHeight={isMobile ? '80%' : null}
						height={isMobile ? 'max-content' : '100%'}
						px={[4, 8, 12]}
					>
						{children}
					</Flex>
					{footer}
				</Flex>
			</Flex>
		</Box>
	);
};
