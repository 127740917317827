const AUTHENTICATION_KEY = 'k_auth_token';
const USERNAME_KEY = 'k_auth_username';

/**
 * Save authentication token in local storage.
 *
 * @param token authentication token
 * @returns void
 */
export const saveAuthenticationToken: (token: string) => void = token => {
	if (typeof window !== 'undefined') {
		window?.localStorage.setItem(AUTHENTICATION_KEY, token);
		console.info('Authentication token cached');
	}
};

/**
 * Save the username in local storage, only if the user wants to store it to store it (rememberMe).
 * @param username
 * @returns void
 */
export const saveAuthenticationUsername: (
	username: string
) => void = username => {
	if (typeof window !== 'undefined') {
		window?.localStorage.setItem(USERNAME_KEY, username);
		console.info('Username cached');
	}
};

/**
 * Gets the autenticated token, or null if not defined.
 *
 * - null if the token does not exists
 * - undefined if not in context of browser
 * - token if exists
 *
 * @returns string?
 */
export const getCachedAuthenticationToken: () => string = () => {
	if (typeof window !== 'undefined') {
		return window?.localStorage.getItem(AUTHENTICATION_KEY);
	}

	return null;
};

/**
 * Gets the autenticated username, or null if not defined.
 *
 * - null if the username does not exists
 * - null if not in context of browser
 * - username if exists
 *
 * @returns string?
 */
export const getCachedAuthenticationUsername: () => string = () => {
	if (typeof window !== 'undefined') {
		return window?.localStorage.getItem(USERNAME_KEY);
	}

	return null;
};

/**
 * Removes the authenticated token if any.
 *
 * @returns void
 */
export const clearAuthenticationToken: () => void = () => {
	if (typeof window !== 'undefined') {
		window?.localStorage.removeItem(AUTHENTICATION_KEY);
		console.info('Cached token cleared');
	}
};

/**
 * Removes the authenticated username if any.
 *
 * @returns void
 */
export const clearAuthenticationUsername: () => void = () => {
	if (typeof window !== 'undefined') {
		window?.localStorage.removeItem(USERNAME_KEY);
		console.info('Cached username cleared');
	}
};

/**
 * @returns string The Authorization: header content
 */
export const getAuthorizationHeader = () =>
	`Token ${getCachedAuthenticationToken()}`;
