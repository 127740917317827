import {Flex} from '@chakra-ui/react';
import {useTranslation} from 'modules/i18n';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

export const Cookies: React.FC = () => {
	const {t} = useTranslation();
	return (
		<Flex>
			<CookieConsent
				location='bottom'
				buttonText={t('cookies.button_text')}
				cookieName='Geosophy-saas'
				style={{background: '#2B373B'}}
				buttonStyle={{color: '#4e503b', fontSize: '13px'}}
				expires={150}
			>
				{t('cookies.consent')}{' '}
				<span style={{fontSize: '10px'}}>{t('cookies.consent_2')}</span>
			</CookieConsent>
		</Flex>
	);
};
