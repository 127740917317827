import {Flex, Img} from '@chakra-ui/react';
import React from 'react';

export interface DesktopSideImageProps {
	image?: string;
	height?: string;
	logo?: string;
}

export const DesktopSideImage: React.FC<DesktopSideImageProps> = ({
	image,
	height,
	logo
}: DesktopSideImageProps) => (
	<>
		<Flex width='27vw' maxWidth='500px'>
			<Img
				alt='logo'
				src='/images/logo.svg'
				mt={8}
				mx='2vw'
				zIndex={1000}
				position='absolute'
				height='8vw'
				maxHeight='120px'
				minHeight='65px'
				width='8vw'
				maxWidth='120px'
				minWidth='65px'
			/>

			<Flex position='relative'>
				<Img
					alt='banner'
					src={image}
					objectFit='cover'
					objectPosition='center top'
					width='100%'
				/>
			</Flex>
		</Flex>
	</>
);
