import {Address} from '../modules/types';

/**
 * Use this to distinguish undefined or null value from other Boolean Falsable values like 0, [], ''
 *
 * For example :
 * ```
 * const el = element ?? 33;
 * // If that particular case, we cannot distring
 * ```
 *
 * @param element
 * @returns true if undefined or null.
 */
export const isUorN = (element: any) => element == null;

export const anyFunction = (..._args: any[]) => undefined;

export const formatTimeRange = (from?: string, to?: string) => {
	if (from && to) {
		return `${from} - ${to}`;
	}
	if (from) {
		return from;
	}
	return to;
};

export const formatFullAddress = (address?: Address) => {
	let fullAdress = address.address1;

	if (address.address2) {
		fullAdress = `${fullAdress} ${address.address2}`;
	}
	if (address.city) {
		fullAdress = `${fullAdress} ${address.city}`;
	}
	if (address.region) {
		fullAdress = `${fullAdress}, ${address.region}`;
	}
	if (address.postalCode) {
		fullAdress = `${fullAdress}, ${address.postalCode}`;
	}
	return fullAdress;
};

export const formatCurrency = (num?: number) => {
	return `${num.toLocaleString('fr-FR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})} €`;
};

export const roundValue = (value: number, precision: number = 3): number => {
	return Number.parseFloat(Math.round(value).toPrecision(precision));
};

export const roundToThousands = (num: number): number => {
	const _digits = Math.floor(Math.log10(num));
	const _precision = _digits <= 3 ? 0 : 1;
	const _powered = 10 ** (_digits - _precision);
	const _signif = 10 ** (_digits - 3 - _precision);
	return Math.round(num / _powered) * _signif;
};

export const round = (num: number, significant: number): number => {
	if (num < 1) {
		const _powered = 10 ** significant;
		return Math.round(num * _powered) / _powered;
	}

	const _digits = Math.floor(Math.log10(num)) + 1;
	const _powered = 10 ** (_digits - significant);
	return Math.round(num / _powered) * _powered;
};

/**
 * Format Investment.
 * @param range : a plain range in the form of XXXXXX.XX-XXXXXX.XX .
 * @returns a value in form of 'XXX-XXX' with 2 significant digits.
 */
export const formatInvestment = (range: string): string => {
	const _parts: string[] = range.split('-');

	return `${roundToThousands(Number.parseFloat(_parts[0]))}-${roundToThousands(
		Number.parseFloat(_parts[1])
	)}`;
};

/**
 *
 * @param range : A range in the form of `XXX[.XX]-XXX[.XX]` or a fixed value like `XXX[.XX]`.
 * @returns a formatted range or fix value with 2 significant value or 1 significant value for number under 1.
 */
export const carbonFootprint = (locale: string, range: string): string => {
	const _parts: string[] = range.split('-');

	let _results = undefined;

	if (_parts.length === 1 || _parts[0] === _parts[1]) {
		const _value = Number.parseFloat(_parts[0]);
		const _formatter = new Intl.NumberFormat(locale, {
			minimumFractionDigits: _value <= 1 ? 1 : _value < 10 ? 1 : 0,
			maximumFractionDigits: _value <= 1 ? 1 : _value < 10 ? 1 : 0,
			minimumSignificantDigits: _value <= 1 ? 1 : 2,
			maximumSignificantDigits: _value <= 1 ? 1 : 2,
			useGrouping: false
		});

		_results = `${_formatter.format(_value)}`;
	} else {
		const _lvalue = Number.parseFloat(_parts[0]);
		const _rvalue = Number.parseFloat(_parts[1]);

		const _lformatter = new Intl.NumberFormat(locale, {
			minimumFractionDigits: _lvalue <= 1 ? 1 : _lvalue < 10 ? 1 : 0,
			maximumFractionDigits: _lvalue <= 1 ? 1 : _lvalue < 10 ? 1 : 0,
			minimumSignificantDigits: _lvalue <= 1 ? 1 : 2,
			maximumSignificantDigits: _lvalue <= 1 ? 1 : 2,
			useGrouping: false
		});
		const _rformatter = new Intl.NumberFormat(locale, {
			minimumFractionDigits: _rvalue <= 1 ? 1 : _rvalue < 10 ? 1 : 0,
			maximumFractionDigits: _rvalue <= 1 ? 1 : _rvalue < 10 ? 1 : 0,
			minimumSignificantDigits: _rvalue <= 1 ? 1 : 2,
			maximumSignificantDigits: _rvalue <= 1 ? 1 : 2,
			useGrouping: false
		});

		_results = `${_lformatter.format(_lvalue)}-${_rformatter.format(_rvalue)}`;
	}

	return _results;
};

export const percentInterval = (percent: number) => {
	const lowValue = Math.round((percent - 15) / 10) * 10;
	const highValue = Math.round((percent + 15) / 10) * 10;
	if (highValue > 100) return lowValue.toFixed(0) + '-100';
	if (lowValue < 0) return '0-' + highValue.toFixed(0);
	return lowValue.toFixed(0) + '-' + highValue.toFixed(0);
};
