import {Request, Response} from 'miragejs';
import {DataSet, DataSwitch, RouteSet} from 'utils/mockSystem/api';
import allScansList from 'utils/mockAssets/scenariiNewRefiningForm/aScanListAtConsumption.json';
import scansFinished from 'utils/mockAssets/scenariiNewRefiningForm/scansFinished.json';
import aScan1V1AtConsumption from 'utils/mockAssets/scenariiNewRefiningForm/aScan1V1AtConsumption.json';
// import aScan4V2AtNotation from 'utils/mockAssets/scenariiNewRefiningForm/aScan4V2AtNotation.json';
import aScan2V2AtConsumption from 'utils/mockAssets/scenariiNewRefiningForm/aScan2V2AtConsumption.json';
// import aScanListV1AtConsumption from 'utils/mockAssets/scenariiNewRefiningForm/aScanListV1AtConsumption.json';
// import aScanListV2AtConsumption from 'utils/mockAssets/scenariiNewRefiningForm/aScanListV2AtConsumption.json';
// import aScanV1AtNotation from 'utils/mockAssets/scenariiNewRefiningForm/aScanV1AtNotation.json';
// import aScanNeutralAtNotation from 'utils/mockAssets/scenariiNewRefiningForm/aScanNeutralAtNotation.json';
/**
 * @param scenario The name of the scenario.
 * @returns Complete set of routes
 */
export const getNewRefiningFormScenarii: () => RouteSet = () => {
	// ///
	// authentication
	// ///
	const _login: [string, DataSet] = [
		'/token/login/',
		new Map<string, DataSwitch>([
			[
				'post',
				{
					samples: [
						{
							name: 'default',
							givenInput: {
								username: 'geo@gmail.com',
								password: 'newRefiningForm'
							},
							thenOutput: {auth_token: 'a-token'},
							withStatusCode: 201
						}
					],
					elseOutput: {
						message: 'badCredentials',
						literal: 'The provided credentials are incorrect haaaa !',
						statusCode: 401
					}
				}
			]
		])
	];
	const _logout: [string, DataSet] = [
		'/token/logout/',
		new Map<string, DataSwitch>([
			[
				'post',
				{
					samples: [
						{
							name: 'default',
							givenInput: null,
							thenOutput: null,
							withStatusCode: 201
						}
					],
					elseOutput: {
						message: 'badCredentials',
						literal: 'The provided credentials are incorrect haaaa !',
						statusCode: 401
					}
				}
			]
		])
	];
	const _me: [string, DataSet] = [
		'/users/me/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					handler: (schema: any, request: Request) => {
						console.log(request.url);
						console.info('info: ****', request.requestHeaders.Authorization);
						if (request.requestHeaders.Authorization !== 'Token a-token') {
							return new Response(401);
						}

						return new Response(200, undefined, {
							email: 'geo@gmail.com',
							id: 1,
							username: 'geo@gmail.com',
							first_name: 'Geo',
							last_name: 'Sophie',
							credit: {
								quantity: 5,
								free_quantity: 5,
								id: 1
							},
							profile: {
								company: 'A company',
								address1: '21 rue de Paris',
								address2: '',
								postalCode: '75000',
								country: 'France',
								city: 'Paris',
								profile: 'individual',
								rgpd: true,
								corporateFunction: '',
								indicator_tech_resources: 0.33,
								indicator_tech_adequacy: 0.34,
								indicator_tech_complexity: 0.33,
								indicator_fin_investment: 0.33,
								indicator_fin_competition: 0.34,
								indicator_fin_roi: 0.33,
								preferred_language: 'fr'
							}
						});
					}
				}
			]
		])
	];
	const _meIndicator: [string, DataSet] = [
		'/users/me/indicator/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: null,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2023-05-16T16:47:13.607997Z',
								data: {
									indicator_tech_resources: 0.4,
									indicator_tech_adequacy: 0.4,
									indicator_tech_complexity: 0.2,
									indicator_fin_investment: 0.33,
									indicator_fin_competition: 0.34,
									indicator_fin_roi: 0.33
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	// ///
	// Label management
	// ///
	const _labelList: [string, DataSet] = [
		'/labels/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: null,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2019-08-24T14:15:22Z',
								data: [
									{
										id: 1,
										name: 'Dossier 1',
										user: 1,
										created_at: '2021-11-08T10:16:24.048835Z',
										updated_at: '2021-11-08T10:16:24.067847Z',
										scans: [
											{
												id: 1,
												name: 'Scan Name',
												mode: 'address',
												type: 'standard',
												x: null,
												y: null,
												address: 'Une adresse, France',
												archived: false,
												calculation_step: 'consumption',
												status: 'done',
												created_at: '2021-11-08T10:16:24.067847Z',
												updated_at: '2021-11-08T10:16:24.067847Z',
												validated: false,
												labels: [1],
												solutions: 0,
												version: 'v1'
											},
											{
												id: 2,
												name: 'Scan Name',
												mode: 'address',
												type: 'professional',
												x: null,
												y: null,
												address: 'Une adresse Pro, France',
												archived: false,
												calculation_step: 'consumption',
												status: 'done',
												created_at: '2021-11-08T10:16:24.067847Z',
												updated_at: '2021-11-08T10:16:24.067847Z',
												validated: false,
												labels: [1],
												solutions: 0,
												version: 'v2'
											}
										]
									},
									{
										id: 2,
										name: 'Dossier 2',
										user: 1,
										created_at: '2021-11-08T10:16:24.048835Z',
										updated_at: '2021-11-08T10:16:24.067847Z',
										scans: [
											{
												id: 5,
												name: 'Scan Name',
												mode: 'address',
												type: 'standard',
												x: null,
												y: null,
												address: 'Une adresse, France',
												archived: false,
												calculation_step: 'consumption',
												status: 'processing',
												created_at: '2021-11-08T10:16:24.067847Z',
												updated_at: '2021-11-08T10:16:24.067847Z',
												validated: false,
												labels: [2],
												solutions: 0,
												version: 'v2'
											},
											{
												id: 6,
												name: 'Scan Name',
												mode: 'address',
												type: 'professional',
												x: null,
												y: null,
												address: 'Une adresse Pro, France',
												archived: false,
												calculation_step: 'consumption',
												status: 'error',
												created_at: '2021-11-08T10:16:24.067847Z',
												updated_at: '2021-11-08T10:16:24.067847Z',
												validated: false,
												labels: [2],
												solutions: 0,
												version: 'v2'
											}
										]
									},
									{
										id: 3,
										name: 'Dossier 3',
										user: 1,
										created_at: '2021-11-08T10:16:24.048835Z',
										updated_at: '2021-11-08T10:16:24.067847Z',
										scans: [
											{
												id: 3,
												name: "Montigny-le-Bretonneux - Rue du Champ d'Avoine",
												mode: 'address',
												type: 'standard',
												address:
													"1 Rue du Champ d'Avoine, Montigny-le-Bretonneux, France",
												x: 2.0299374,
												y: 48.7756772,
												labels: [3],
												status: 'done',
												validated: true,
												calculation_step: 'notations',
												solutions: 3,
												version: 'v2',
												created_at: '2023-03-06T14:18:53.655443Z',
												updated_at: '2023-03-06T14:29:24.358562Z'
											}
										]
									}
								]
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _label1: [string, DataSet] = [
		'/labels/1/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2019-08-24T14:15:22Z',
								data: {
									id: 1,
									name: 'Dossier 1',
									user: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z',
									scans: [
										{
											id: 1,
											name: 'Scan Name',
											mode: 'address',
											type: 'standard',
											x: null,
											y: null,
											address: 'Une adresse, France',
											archived: false,
											calculation_step: 'consumption',
											status: 'done',
											created_at: '2021-11-08T10:16:24.067847Z',
											updated_at: '2021-11-08T10:16:24.067847Z',
											validated: false,
											labels: [1],
											solution: 1,
											version: 'v1',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												}
											]
										},
										{
											id: 2,
											name: 'Scan Name',
											mode: 'address',
											type: 'professional',
											x: null,
											y: null,
											address: 'Une adresse Pro, France',
											archived: false,
											calculation_step: 'consumption',
											status: 'done',
											created_at: '2021-11-08T10:16:24.067847Z',
											updated_at: '2021-11-08T10:16:24.067847Z',
											validated: false,
											labels: [1],
											solution: 1,
											version: 'v1',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												}
											]
										},
										{
											id: 4,
											name: 'Scan Name',
											mode: 'address',
											type: 'professional',
											x: null,
											y: null,
											address: 'Une adresse Pro, France',
											archived: false,
											calculation_step: 'notations',
											status: 'done',
											created_at: '2021-11-08T10:16:24.067847Z',
											updated_at: '2021-11-08T10:16:24.067847Z',
											validated: true,
											labels: [1],
											solution: 1,
											version: 'v1',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												}
											]
										}
									]
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _label2: [string, DataSet] = [
		'/labels/2/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2019-08-24T14:15:22Z',
								data: {
									id: 2,
									name: 'Dossier 2',
									user: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z',
									scans: [
										{
											id: 3,
											name: 'Scan Name',
											mode: 'address',
											type: 'standard',
											x: null,
											y: null,
											address: 'Une adresse, France',
											archived: false,
											calculation_step: 'consumption',
											status: 'processing',
											created_at: '2021-11-08T10:16:24.067847Z',
											updated_at: '2021-11-08T10:16:24.067847Z',
											validated: false,
											labels: [2],
											solution: 1,
											version: 'v1',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												}
											]
										},
										{
											id: 4,
											name: 'Scan Name',
											mode: 'address',
											type: 'standard',
											x: null,
											y: null,
											address: 'Une adresse, France',
											archived: false,
											calculation_step: 'notations',
											status: 'processing',
											created_at: '2021-11-08T10:16:24.067847Z',
											updated_at: '2021-11-08T10:16:24.067847Z',
											validated: true,
											labels: [2],
											solution: 1,
											version: 'v1',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												}
											]
										}
									]
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _label3: [string, DataSet] = [
		'/labels/3/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2019-08-24T14:15:22Z',
								data: {
									id: 3,
									name: 'Dossier 3',
									user: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z',
									scans: [
										{
											id: 3,
											name: "Montigny-le-Bretonneux - Rue du Champ d'Avoine",
											mode: 'address',
											type: 'standard',
											address:
												"1 Rue du Champ d'Avoine, Montigny-le-Bretonneux, France",
											x: 2.0299374,
											y: 48.7756772,
											labels: [3],
											status: 'done',
											validated: true,
											solutions: 5,
											version: 'v1',
											calculation_step: 'notations',
											created_at: '2023-03-06T14:18:53.655443Z',
											updated_at: '2023-03-06T14:29:24.358562Z',
											json: [
												{
													solution_geoth: 'Oligocène',
													type_solution: 'nappe',
													puissance_kW: 23.433528969924406,
													prof_mean: 25.499980612663425,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 911742.0,
													investissement_fourchette: '787809.0-1035674.0',
													pourc_couv: 44.994,
													economie_eqCO2_t_par_an: 21.30047978335524,
													bilan_carbone: '43.9487482093094',
													Note_Ressource: 3.0863123578161,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526609237784073,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330884531401,
													Note_Combinee: 4.284804618892037
												},
												{
													solution_geoth: 'Eocène moyen et inférieur',
													type_solution: 'nappe',
													puissance_kW: 23.43272541496321,
													prof_mean: 75.50011429242664,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'green',
													cout_travaux: 2073285.0,
													investissement_fourchette: '1928142.0-2218428.0',
													pourc_couv: 44.993,
													economie_eqCO2_t_par_an: 21.299833562652825,
													bilan_carbone: '43.94939443001182',
													Note_Ressource: 3.0862336696909956,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 0.0,
													Note_Concurrence: 8.95,
													Note_ROI: 0.0,
													Note_Technique: 5.526583270702789,
													Note_Commerciale: 3.043,
													Note_Bilan_Carbone: 7.696330895255437,
													Note_Combinee: 4.284791635351395
												},
												{
													solution_geoth: 'sondes_meilleure_couverture',
													type_solution: 'sondes',
													puissance_kW: 322.0,
													prof_mean: 200,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'orange',
													cout_travaux: 1400695.0,
													investissement_fourchette: '1361561.0-1439829.0',
													pourc_couv: 100.0,
													economie_eqCO2_t_par_an: 43.81360107248191,
													bilan_carbone: '21.435626920182735',
													Note_Ressource: 10.0,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 5.5,
													Note_Concurrence: 10.0,
													Note_ROI: 0.0,
													Note_Technique: 6.246500927763809,
													Note_Commerciale: 5.215000000000001,
													Note_Bilan_Carbone: 7.681543349810382,
													Note_Combinee: 5.730750463881905
												},
												{
													solution_geoth: 'sondes_meilleure_note_geosophy',
													type_solution: 'sondes',
													puissance_kW: 91.0,
													prof_mean: 200,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'orange',
													cout_travaux: 408435.0,
													investissement_fourchette: '396998.0-419872.0',
													pourc_couv: 98.497,
													economie_eqCO2_t_par_an: 43.61807236958364,
													bilan_carbone: '21.631155623081',
													Note_Ressource: 8.173687367132189,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 5.04,
													Note_Concurrence: 10.0,
													Note_ROI: 6.84,
													Note_Technique: 5.764354392686707,
													Note_Commerciale: 7.3204,
													Note_Bilan_Carbone: 7.683638296471623,
													Note_Combinee: 6.542377196343354
												},
												{
													solution_geoth: 'sondes_meilleur_ROI',
													type_solution: 'sondes',
													puissance_kW: 21.0,
													prof_mean: 200,
													dist_metro: 450.7971389024581,
													prix_m2: 1123.6666666666667,
													conso_chaud: 161532936.96397874,
													conso_froid: -77827063.03602119,
													Surface: 3650.0,
													Accessibilite: 0,
													Site_occupe: 1,
													risk: 'orange',
													cout_travaux: 107750.0,
													investissement_fourchette: '103811.0-111690.0',
													pourc_couv: 40.787,
													economie_eqCO2_t_par_an: 19.309505958480624,
													bilan_carbone: '45.939722034184015',
													Note_Ressource: 2.7890817001074284,
													Note_Adequation: 8.406253410896353,
													Note_Complexite: 5.0,
													Note_Investissement: 2.9,
													Note_Concurrence: 8.12,
													Note_ROI: 8.59,
													Note_Technique: 4.3428184965921695,
													Note_Commerciale: 6.5525,
													Note_Bilan_Carbone: 7.696336887603282,
													Note_Combinee: 5.447659248296085
												}
											]
											// studyData: {
											// 	id: 244,
											// 	json: {
											// 		consumption_mode: 'global',
											// 		consumption: 1050043.129194539,
											// 		consumption_heating: 648019.160686154,
											// 		consumption_conditioning: -402023.96850838506,
											// 		accessibility_parcel: 0,
											// 		accessibility_building: 0,
											// 		accessibility_basement: 0,
											// 		occupation: 0,
											// 		surface: 10689.48289839282,
											// 		surface_desk: 10689.48289839282,
											// 		surface_flat: 0,
											// 		surface_shop: 0,
											// 		surface_logistics: 0,
											// 		surface_hotel: 0,
											// 		neighborhood: {
											// 			parcels: [],
											// 			buildings: [],
											// 			coords: {
											// 				x: 2.3165784,
											// 				y: 48.8701231
											// 			}
											// 		},
											// 		surface_ratio_flat: 0,
											// 		surface_ratio_desk: 1,
											// 		surface_ratio_shop: 0,
											// 		surface_ratio_logistics: 0,
											// 		surface_ratio_hotel: 0,
											// 		conditioning_temp: 'NO_CONDITIONING',
											// 		heating_temp: 'TEMP_HEAT_55_TO_45'
											// 	}
											// }
										}
									]
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	// ///
	// Studydata Nomenclatura
	// ///
	const _studyDataNomenclatura: [string, DataSet] = [
		'/nomenclatura/refining/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2019-08-24T14:15:22Z',
								data: {
									resources: [
										{
											id: 1,
											name: 'Gas Resource',
											key: 'gas',
											order: 1,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 2,
											name: 'Electicity Resource',
											key: 'electricity',
											order: 2,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 3,
											name: 'Network for heating only',
											key: 'network-heating',
											order: 3,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 4,
											name: 'Network for cooling only',
											key: 'network-cooling',
											order: 4,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 5,
											name: 'Biomass',
											key: 'biomass',
											order: 5,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										}
									],
									typologies: [
										{
											id: 1,
											key: 'desk',
											name: 'Desk Surface',
											sheetPath: 'typology/desk',
											order: 1,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 2,
											key: 'flat',
											name: 'Flat Surface',
											sheetPath: 'typology/flat',
											order: 2,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 3,
											key: 'hotel',
											name: 'Hotel Surface',
											sheetPath: 'typology/hotel',
											order: 3,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 4,
											key: 'shop',
											name: 'Shop Surface',
											sheetPath: 'typology/shop',
											order: 4,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										}
									],
									installations: [
										{
											id: 1,
											key: 'gas',
											name: 'Gas',
											resource: {
												id: 1,
												key: 'gas',
												name: 'Gas Resource',
												order: 1,
												created_at: '2021-11-08T10:16:24.048835Z',
												updated_at: '2021-11-08T10:16:24.067847Z'
											},
											heating: true,
											cooling: false,
											order: 1,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 2,
											key: 'heat-pump',
											name: 'Heat pump',
											resource: {
												id: 2,
												key: 'electricity',
												name: 'Electricity Resource',
												order: 2,
												created_at: '2021-11-08T10:16:24.048835Z',
												updated_at: '2021-11-08T10:16:24.067847Z'
											},
											heating: true,
											cooling: true,
											order: 2,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 3,
											key: 'network-heating',
											name: 'Network for Heating',
											resource: {
												id: 3,
												key: 'network-heating',
												name: 'Network heating resource',
												order: 3,
												created_at: '2021-11-08T10:16:24.048835Z',
												updated_at: '2021-11-08T10:16:24.067847Z'
											},
											heating: true,
											cooling: false,
											order: 3,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 4,
											key: 'network-cooling',
											name: 'Network for Cooling',
											resource: {
												id: 4,
												key: 'network-cooling',
												name: 'Network cooling resource',
												order: 4,
												created_at: '2021-11-08T10:16:24.048835Z',
												updated_at: '2021-11-08T10:16:24.067847Z'
											},
											heating: false,
											cooling: true,
											order: 4,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 5,
											key: 'biomass',
											name: 'Biomass for heating',
											resource: {
												id: 5,
												key: 'biomass',
												name: 'Biomass',
												order: 5,
												created_at: '2021-11-08T10:16:24.048835Z',
												updated_at: '2021-11-08T10:16:24.067847Z'
											},
											heating: true,
											cooling: false,
											order: 5,
											dtEnd: null,
											dtStart: null,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										}
									],
									temperatures: [
										{
											id: 1,
											key: 'heating-max-45',
											dtEnd: null,
											dtStart: null,
											min: null,
											max: 45,
											installationType: 'heating',
											order: 1,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 2,
											key: 'heating-min-45-max-55',
											dtEnd: null,
											dtStart: null,
											min: 45,
											max: 55,
											installationType: 'heating',
											order: 2,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 3,
											key: 'heating-min-55-max-70',
											dtEnd: null,
											dtStart: null,
											min: 55,
											max: 70,
											installationType: 'heating',
											order: 3,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 4,
											key: 'heating-min-70',
											dtEnd: null,
											dtStart: null,
											min: 70,
											max: null,
											installationType: 'heating',
											order: 4,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},

										{
											id: 5,
											key: 'cooling-none',
											dtEnd: null,
											dtStart: null,
											min: null,
											max: null,
											installationType: 'cooling',
											order: 1,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 6,
											key: 'cooling-max-10',
											dtEnd: null,
											dtStart: null,
											min: null,
											max: 10,
											installationType: 'cooling',
											order: 2,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 7,
											key: 'cooling-min-10-max-15',
											dtEnd: null,
											dtStart: null,
											min: 10,
											max: 15,
											installationType: 'cooling',
											order: 3,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										},
										{
											id: 8,
											key: 'cooling-min-15',
											dtEnd: null,
											dtStart: null,
											min: 15,
											max: null,
											installationType: 'cooling',
											order: 4,
											created_at: '2021-11-08T10:16:24.048835Z',
											updated_at: '2021-11-08T10:16:24.067847Z'
										}
									]
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	// ///
	// Test management
	// ///

	const _testFilter: [string, DataSet] = [
		'/freeEvaluations/filter/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: {
								ids: '2'
							},

							givenInput: null,
							thenOutput: [
								{
									id: 2,
									mode: 'address',
									address: '55 Rue du Faubourg Saint-Honoré, Paris, France',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_1',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								}
							],
							withStatusCode: 200
						},
						{
							givenQueryParameters: {
								last: '5',
								archived: 'false'
							},

							givenInput: null,
							thenOutput: [
								{
									id: 2,
									mode: 'address',
									address: '55 Rue du Faubourg Saint-Honoré, Paris, France',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_1',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 3,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_2',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 4,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_3',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								}
							],
							withStatusCode: 200
						},
						{
							name: 'default',
							givenQueryParameters: {
								archived: 'false'
							},
							givenInput: null,
							thenOutput: [
								{
									id: 2,
									mode: 'address',
									address: '55 Rue du Faubourg Saint-Honoré, Paris, France',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_1',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 3,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_2',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 4,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_3',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 5,
									mode: 'address',
									address: "Une adresse qui n'en finit jamais, France",
									x: 2.3165784,
									y: 48.8701231,
									status: 'processing',
									score: 'score_3',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 5,
									mode: 'address',
									address: 'Une adresse en erreur, France',
									x: 2.3165784,
									y: 48.8701231,
									status: 'error',
									score: 'score_3',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								}
							],
							withStatusCode: 200
						},
						{
							name: 'allFinished',
							givenQueryParameters: {
								archived: 'false'
							},
							givenInput: null,
							thenOutput: [
								{
									id: 2,
									mode: 'address',
									address: '55 Rue du Faubourg Saint-Honoré, Paris, France',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_1',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 3,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_2',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								},
								{
									id: 4,
									mode: 'coordinates',
									address: '',
									x: 2.3165784,
									y: 48.8701231,
									status: 'done',
									score: 'score_3',
									cost: 1,
									created_at: '2021-11-08T10:16:24.048835Z',
									updated_at: '2021-11-08T10:16:24.067847Z'
								}
							],
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	// ///
	// Scan mamangement
	// ///

	const _scanFilter: [string, DataSet] = [
		'/scans/filter/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: {
								last: '5',
								archived: 'false',
								validated: 'true'
							},

							givenInput: null,
							thenOutput: {
								error: false,
								message: 'listed',
								at: '2021-11-22T13:13:25.587494Z',
								data: [
									{
										id: 3,
										name: "Montigny-le-Bretonneux - Rue du Champ d'Avoine",
										mode: 'address',
										type: 'standard',
										address:
											"1 Rue du Champ d'Avoine, Montigny-le-Bretonneux, France",
										x: 2.0299374,
										y: 48.7756772,
										parcels: ['78423000AN0016'],
										buildings: [],
										labels: [3],
										status: 'done',
										validated: true,
										calculation_step: 'notations',
										created_at: '2023-03-06T14:18:53.655443Z',
										updated_at: '2023-03-06T14:29:24.358562Z',
										solutions: 5,
										version: 'v2'
									}
								]
							},
							withStatusCode: 200
						},
						{
							givenQueryParameters: {
								archived: 'false',
								validated: 'true'
							},

							givenInput: null,
							thenOutput: scansFinished,
							withStatusCode: 200
						},
						{
							givenQueryParameters: {
								validated: 'false'
							},

							givenInput: null,
							thenOutput: allScansList,
							withStatusCode: 200
						}
					]
				}
			]
		])
	];

	// const _scanListAtConsumptionV1: [string, DataSet] = [
	// 	'/scans/v1/',
	// 	new Map<string, DataSwitch>([
	// 		[
	// 			'get',
	// 			{
	// 				samples: [
	// 					{
	// 						givenQueryParameters: {
	// 							ids: '1'
	// 						},
	// 						givenInput: null,
	// 						thenOutput: aScanListV1AtConsumption,
	// 						withStatusCode: 200
	// 					}
	// 				],
	// 				elseOutput: {
	// 					message: 'notFoundSample',
	// 					literal: 'Sorry, did not found the correct sample.',
	// 					statusCode: 400
	// 				}
	// 			}
	// 		]
	// 	])
	// ];

	// const _scanListAtConsumptionV2: [string, DataSet] = [
	// 	'/scans/v2/',
	// 	new Map<string, DataSwitch>([
	// 		[
	// 			'get',
	// 			{
	// 				samples: [
	// 					{
	// 						givenQueryParameters: {
	// 							ids: '2,3,4'
	// 						},
	// 						givenInput: null,
	// 						thenOutput: aScanListV2AtConsumption,
	// 						withStatusCode: 200
	// 					},
	// 					{
	// 						givenQueryParameters: {
	// 							ids: '2'
	// 						},
	// 						givenInput: null,
	// 						thenOutput: aScan2V2AtConsumption,
	// 						withStatusCode: 200
	// 					}
	// 				],
	// 				elseOutput: {
	// 					message: 'notFoundSample',
	// 					literal: 'Sorry, did not found the correct sample.',
	// 					statusCode: 400
	// 				}
	// 			}
	// 		]
	// 	])
	// ];

	const _scanAtConsumptionV1: [string, DataSet] = [
		'/scans/v1/1/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: aScan1V1AtConsumption,
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];
	const _scanAtNotationV1: [string, DataSet] = [
		'/scans/v1/3/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							// thenOutput: aScanV1AtNotation,
							thenOutput: aScan1V1AtConsumption,
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];
	// const _scanAtNotationNeutral: [string, DataSet] = [
	// 	'/scans/3/',
	// 	new Map<string, DataSwitch>([
	// 		[
	// 			'get',
	// 			{
	// 				samples: [
	// 					{
	// 						givenQueryParameters: undefined,
	// 						givenInput: null,
	// 						thenOutput: aScanNeutralAtNotation,
	// 						withStatusCode: 200
	// 					}
	// 				],
	// 				elseOutput: {
	// 					message: 'notFoundSample',
	// 					literal: 'Sorry, did not found the correct sample.',
	// 					statusCode: 400
	// 				}
	// 			}
	// 		]
	// 	])
	// ];

	const _scanAtNotationV2_3: [string, DataSet] = [
		'/scans/v2/3/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: aScan2V2AtConsumption,
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];
	const _scanAtNotationV2: [string, DataSet] = [
		'/scans/v2/4/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: aScan2V2AtConsumption,
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];
	const _scanAtConsumptionV2: [string, DataSet] = [
		'/scans/v2/2/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: aScan2V2AtConsumption,
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _scanVersionV1: [string, DataSet] = [
		'/scans/1/version/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: {
									id: 1,
									version: 'v1'
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _scanVersionV2: [string, DataSet] = [
		'/scans/2/version/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: {
									id: 2,
									version: 'v2'
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];
	const _scan3VersionV1: [string, DataSet] = [
		'/scans/3/version/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: undefined,
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: {
									id: 3,
									version: 'v1'
								}
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _scanVersions: [string, DataSet] = [
		'/scans/version/',
		new Map<string, DataSwitch>([
			[
				'get',
				{
					samples: [
						{
							givenQueryParameters: {
								ids: '1,2,3,4'
							},
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: [
									{
										id: 1,
										version: 'v1'
									},
									{
										id: 2,
										version: 'v2'
									},
									{
										id: 3,
										version: 'v1'
									},
									{
										id: 4,
										version: 'v2'
									}
								]
							},
							withStatusCode: 200
						},
						{
							givenQueryParameters: {
								ids: '1,2,4'
							},
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: [
									{
										id: 1,
										version: 'v1'
									},
									{
										id: 2,
										version: 'v2'
									},
									{
										id: 4,
										version: 'v2'
									}
								]
							},
							withStatusCode: 200
						},
						{
							givenQueryParameters: {
								ids: '1,2,3'
							},
							givenInput: null,
							thenOutput: {
								error: false,
								message: 'retrieved',
								at: '2021-11-22T13:13:25.587494Z',
								data: [
									{
										id: 1,
										version: 'v1'
									},
									{
										id: 2,
										version: 'v2'
									},
									{
										id: 3,
										version: 'v1'
									}
								]
							},
							withStatusCode: 200
						}
					],
					elseOutput: {
						message: 'notFoundSample',
						literal: 'Sorry, did not found the correct sample.',
						statusCode: 400
					}
				}
			]
		])
	];

	const _scanRefineV1: [string, DataSet] = [
		'/scan/ops/refinings/v1/1/',
		new Map<string, DataSwitch>([
			[
				'put',
				{
					handler: (schema: any, request: Request) => {
						const parsedBody = JSON.parse(request.requestBody);
						console.log(request.url, parsedBody);
						console.info('info: ****', request.requestHeaders.Authorization);
						if (request.requestHeaders.Authorization !== 'Token a-token') {
							return new Response(401);
						}

						return new Response(200, undefined, {});
					}
				}
			]
		])
	];

	const _scanRefineV2: [string, DataSet] = [
		'/scan/ops/refinings/v2/2/',
		new Map<string, DataSwitch>([
			[
				'put',
				{
					handler: (schema: any, request: Request) => {
						const parsedBody = JSON.parse(request.requestBody);
						console.log(request.url, parsedBody);
						console.info('info: ****', request.requestHeaders.Authorization);
						if (request.requestHeaders.Authorization !== 'Token a-token') {
							return new Response(401);
						}

						return new Response(200, undefined, {});
					}
				}
			]
		])
	];

	return new Map<string, DataSet>([
		_login,
		_logout,
		_me,
		_meIndicator,
		_testFilter,
		_scanFilter,
		_labelList,
		_label1,
		_label2,
		_label3,
		_scanAtConsumptionV1,
		_scanAtConsumptionV2,
		// _scanListAtConsumptionV1,
		// _scanListAtConsumptionV2,
		_scanAtNotationV1,
		_scanVersionV1,
		_scanVersionV2,
		_scan3VersionV1,
		_scanVersions,
		_studyDataNomenclatura,
		// _scanAtNotationNeutral,
		_scanRefineV1,
		_scanRefineV2,
		_scanAtNotationV2,
		_scanAtNotationV2_3
	]);
};
